<template>
  <v-layout column class="footer-contents">
    <v-layout row>
      <div class="space-right">
        <strong>Live Miles:</strong>
        {{ distanceString }}
      </div>
      <div class="space-right">
        <strong>Estimated Time:</strong>
        {{ estimatedTime }}
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import marketRates from '@/services/marketRates'
import { metersToMilesString } from '@/utils/distance'
import { secondsToDaysAndHoursString } from '@/utils/time'

export default {
  props: {
    toggled: { type: Boolean, default: false },
    tripData: {
      type: Object,
      default: () => ({
        rates: [],
        charges: [],
        stops: [],
        distance: 0,
        duration: 0,
        hourlyDiff: 0,
        mileageRate: 0,
        hourlyRate: 0,
        dailyRate: 0,
        deadMileCalcus: 0,
      }),
    },
  },
  data() {
    return {
      estimatedTime: 0,
      rateMatrix: {},
      vehicleTypes: [],
    }
  },
  computed: {
    distanceString() {
      return metersToMilesString(this.tripData?.distance)
    },
  },
  watch: {
    tripData: {
      deep: true,
      handler() {
        this.estimate()
      },
    },
    toggled() {
      this.estimate()
    },
  },
  async mounted() {
    this.getMarketRates()
    this.getVehicleTypes()
  },
  methods: {
    async getMarketRates() {
      try {
        const response = await marketRates.tableView({}, true)
        const marketRateList = (response.data && response.data.resultList) || []

        marketRateList.forEach((rate) => {
          if (!this.rateMatrix[rate.vehicleType]) {
            this.rateMatrix[rate.vehicleType] = {}
          }
          this.rateMatrix[rate.vehicleType][rate.marketRateType] = rate.highRate
        })
      } catch (e) {
        // Handle error here
      }
    },
    async getVehicleTypes() {
      try {
        const response = await this.$store.dispatch('types/getVehicleTypes')
        this.vehicleTypes = response.data.resultList.map((vehicleType) => {
          return {
            description: vehicleType.description,
            id: vehicleType.id,
            key: vehicleType.key,
            label: vehicleType.label,
          }
        })
      } catch (e) {
        // Handle error here
      }
    },
    async estimate() {
      // Destructure commonly used properties
      const {
        hourlyDiff,
        drivingTime,
        calendarDays,
        requiredVehicles,
        distance,
        deadMileCalcus,
      } = this.tripData

      // Calculate time and hourCount
      const { time, hourCount } = this.calculateTimeAndHourCount(
        hourlyDiff,
        drivingTime,
        calendarDays
      )

      this.estimatedTime = secondsToDaysAndHoursString(time)

      // Calculate rates
      const { mileageRate, hourlyRate, dailyRate } = this.calculateRates(
        requiredVehicles,
        distance,
        deadMileCalcus,
        hourCount,
        calendarDays
      )

      // Update tripData rates
      Object.assign(this.tripData, {
        dailyRate,
        hourlyRate,
        mileageRate,
      })
    },
    calculateTimeAndHourCount(hourlyDiff, drivingTime, calendarDays) {
      let time = Math.max(
        drivingTime || 0,
        (hourlyDiff || 0) * 3600,
        (calendarDays || 0) * 86400
      )

      let hourCount = hourlyDiff

      if (calendarDays === 1) {
        if (hourlyDiff * 3600 >= drivingTime) {
          time = hourlyDiff * 3600
        } else {
          time = drivingTime
          hourCount = drivingTime / 3600
        }
      }

      return { time, hourCount }
    },
    calculateRates(
      requiredVehicles,
      distance,
      deadMileCalcus,
      hourCount,
      calendarDays
    ) {
      const pairVehiclesToRates = this.pairVehiclesToRates(requiredVehicles)

      const mileageRate =
        this.calculateRate(pairVehiclesToRates, 'Mileage Rate', distance) +
        (deadMileCalcus || 0)
      const hourlyRate =
        this.calculateRate(pairVehiclesToRates, 'Hourly Rate') * hourCount
      const dailyRate =
        this.calculateRate(pairVehiclesToRates, 'Daily Rate') * calendarDays

      return { mileageRate, hourlyRate, dailyRate }
    },
    pairVehiclesToRates(requiredVehicles) {
      return requiredVehicles.reduce((acc, vehicle) => {
        const vehicleType = this.vehicleTypes.find(
          (v) => v.id === vehicle.vehicleType.id
        )
        if (!vehicleType) return acc

        if (!acc[vehicleType.label]) {
          acc[vehicleType.label] = { quantity: 0 }
        }

        acc[vehicleType.label].quantity += vehicle.quantity
        Object.assign(
          acc[vehicleType.label],
          this.rateMatrix[vehicleType.label]
        )

        return acc
      }, {})
    },
    calculateRate(pairVehiclesToRates, rateType, distance = 1) {
      return (
        Object.keys(pairVehiclesToRates).reduce((acc, vehicle) => {
          const rate = pairVehiclesToRates[vehicle][rateType]
          if (rate) {
            acc += rate * parseInt(pairVehiclesToRates[vehicle].quantity, 10)
          }
          return acc
        }, 0) *
        (rateType === 'Mileage Rate'
          ? metersToMilesString(distance).split('m')[0]
          : 1)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.space-right {
  margin-right: 8px;
}
</style>
