
import { DateTime } from 'luxon'
import IconPlace from '@/cr/components/icons/IconPlace.vue'
import IconCalendar from '@/cr/components/icons/IconCalendar.vue'
import { pluralize } from '@/utils/string'
import CRSidebarDialog from '@/cr/components/CRSidebarDialog.vue'
import { siblings } from '@/services/trips'

export default {
  components: {
    IconPlace,
    IconCalendar,
    CRSidebarDialog,
  },
  watch: {
    tripId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getSiblingTrips()
        }
      },
    },
  },
  props: {
    tripId: {
      type: Number,
      required: true,
    },
    impactedTripIds: {
      type: Array,
      required: true,
    },
  },
  model: {
    prop: 'impactedTripIds',
    event: 'update',
  },
  data() {
    return {
      updateRecurringTrips: false,
      siblingTrips: [],
      selectingTrips: false,
    }
  },
  methods: {
    selectAllTrips() {
      this.impactedTripIds = this.siblingTrips.map((trip) => trip.tripId)
      this.$emit('update', this.impactedTripIds)
    },
    unselectAllTrips() {
      this.impactedTripIds = []
      this.$emit('update', [])
    },
    async getSiblingTrips() {
      const { data } = await siblings(this.tripId)
      this.siblingTrips = data.trips
    },
    pluralizeVehicle(requestedVehicle) {
      return `${requestedVehicle.quantity} ${pluralize(
        requestedVehicle.quantity,
        requestedVehicle.vehicleType
      )}`
    },
    getSimpleDate(date) {
      return DateTime.fromISO(date).toLocaleString({
        dateStyle: 'short',
      })
    },
  },
}
