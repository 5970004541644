<template>
  <v-container :class="sideBarToggled" fluid>
    <h1 class="page-header">{{ modeTitle }} Reservation Trip</h1>

    <section class="sheet">
      <div
        v-if="reservation && reservation.trip && reservation.trip.customer"
        class="customer-info"
      >
        <v-container padded>
          <v-layout wrap padded>
            <v-flex>
              <v-layout padded>
                <v-flex>
                  <div class="customer-name">
                    <!-- TODO: Dont want block level tags in a span. Redo this. -->
                    <div>
                      Customer
                      <h3>
                        {{ reservation.trip.customer.firstName }}
                        {{ reservation.trip.customer.lastName }}
                      </h3>
                    </div>
                  </div>
                </v-flex>
                <v-flex v-if="reservation.trip.customer">
                  <div class="customer-email">
                    <div>
                      Email
                      <h3>{{ reservation.trip.customer.email }}</h3>
                    </div>
                  </div>
                </v-flex>
                <v-flex v-if="reservation.trip.customer">
                  <div class="customer-phone">
                    <div>
                      Phone
                      <h3>
                        {{ phoneFormatFilter(reservation.trip.customer.phone) }}
                      </h3>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout padded>
                <v-flex v-if="reservation.trip.customer">
                  <div class="customer-account">
                    <div>
                      Organization
                      <h3>
                        {{
                          reservation.trip.customer &&
                          reservation.trip.customer.organization
                            ? reservation.trip.customer.organization
                            : '---'
                        }}
                      </h3>
                    </div>
                  </div>
                </v-flex>
                <v-flex
                  v-if="
                    reservation.trip.customer &&
                    reservation.trip.customer.bookings &&
                    reservation.companyId === currentUser.companyId
                  "
                >
                  <div class="customer-account">
                    <div>
                      Bookings Per Year
                      <h3>
                        {{ reservation.trip.customer.bookings }}
                      </h3>
                    </div>
                  </div>
                </v-flex>
                <v-flex v-if="isModeAdd">
                  <div class="customer-quotes">
                    <h3>
                      <a
                        v-if="showPastQuotes === false && isModeAdd"
                        @click="showPastQuotes = true"
                      >
                        Check Past Quotes
                      </a>
                    </h3>
                    <h3>
                      <a
                        v-if="showPastQuotes === true && isModeAdd"
                        @click="showPastQuotes = false"
                      >
                        Back to Quote
                      </a>
                    </h3>
                  </div>
                </v-flex>
                <v-flex v-if="!isModeView">
                  <div
                    class="customer-header-actions"
                    style="text-align: right"
                  >
                    <v-btn
                      class="btn-primaryaction"
                      @click="(evt) => editCustomerSelection(trip.customerId)"
                    >
                      Edit Customer
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </section>

    <section class="sheet pa-0">
      <UserSelector
        v-model="userData"
        mode="edit"
        :is-customer-selected="isTripContactSelected"
        user-title="Trip Contact"
        style="margin-right: 10px"
        @set-is-customer-selected="
          (value) => {
            isTripContactSelected = value
          }
        "
        @clear="
          (value) => {
            trip.tripContact = value
          }
        "
        @saveAndProceed="
          (payload) => {
            trip.tripContactId = payload.id
          }
        "
      />
    </section>

    <section class="sheet">
      <TripsFormNotes
        :trip-data="trip"
        :mode="mode"
        :reservation-id="parseInt(id)"
      />
    </section>

    <section v-if="!showPastQuotes" class="sheet">
      <div v-if="isShuttleCreateQuoteEnabled && currentTrip">
        <QuoteFormTripDetailNew />
        <ImpactedTripSelector
          v-if="isRecurringTrip"
          :tripId="trip.tripId"
          v-model="impactedTripIds"
        />
        <v-layout class="actual-footer" justify-end align-center>
          <v-btn class="btn-secondaryaction" @click="backToDetail">
            Cancel
          </v-btn>
          <v-btn
            class="btn-primaryaction"
            :loading="lockSaveButton"
            mr-1
            style="margin-right: 0"
            @click="validateTripItineraryConflicts"
          >
            Save
          </v-btn>
        </v-layout>
      </div>
      <TripsFormDetail
        v-else-if="reservation"
        :id="`quote-form-trip-detail-1`"
        v-model="trip"
        :reservation-id="parseInt(id)"
        style="padding: 0"
        :validation-key="validationKey"
        :trip-index="0"
        :trip-data="trip"
        :trip-types="tripTypes"
        :vehicle-types="vehicleTypes"
        :toggled="toggleSubTabs === 0"
        :mode="mode"
        :is-charter-up-quote="reservation.isCharterUP"
        :hotel-information="reservation.hotelInformation"
        :hotel-requirement.sync="reservation.hotelRequirement"
        :reservation-risks="reservation.risks"
        :incomplete-itinerary="reservation.incompleteItinerary"
        @validation-results="captureValidationResults"
        @set-due-date="setDueDateKey"
      >
        <template slot="inline-buttons">
          <ImpactedTripSelector
            v-if="isRecurringTrip"
            :tripId="trip.tripId"
            v-model="impactedTripIds"
          />
        </template>
        <template slot="buttons">
          <v-layout class="actual-footer" justify-end align-center>
            <v-btn class="btn-secondaryaction" @click="backToDetail">
              Cancel
            </v-btn>
            <v-btn
              class="btn-primaryaction"
              :loading="lockSaveButton"
              mr-1
              style="margin-right: 0"
              @click="validateTripItineraryConflicts"
            >
              Save
            </v-btn>
          </v-layout>
        </template>
      </TripsFormDetail>
    </section>
    <v-snackbar v-model="saveQuoteError" color="error">
      {{ saveQuoteErrorMessage }}
    </v-snackbar>

    <v-dialog v-model="unsavedChangesWarningDialog" :max-width="'600px'">
      <Confirm
        :id="`trips-form-unsaved-changes`"
        header="Unsaved Changes"
        message="Are you sure you want to leave this page? Any unsaved changes will be lost."
        confirm-button-text="Leave Page"
        cancel-button-text="Stay on Page"
        :is-dialog="true"
        @confirm="confirmLeave"
        @cancel="confirmStay"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import op from 'simple-object-path'

import { v4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { authComputed } from '@/state/helpers'

import { filter } from '@/utils/filter'
import { currencyFilter } from '@/utils/currency'
import { phoneFormatFilter } from '@/utils/phone'
import { deepClone } from '@/utils/deepClone'

import TripsFormDetail from '@/components/TripsFormDetail.vue'
import TripsFormNotes from '@/components/TripsFormNotes.vue'
import UserSelector from '@/components/UserSelector.vue'
import ImpactedTripSelector from '@/components/ImpactedTripSelector.vue'
import Confirm from '@/components/Confirm.vue'
import { DateTime } from 'luxon'
import { validateTrip } from '@/services/reservations'
import exchangeRate from '@/services/exchangeRate'
import { buildValidateTripPayload } from '@/utils/tripAssignments'
// TODO: Move this mixin and its dependencies to a separate file
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'
import { convertTripDTOsToRouteTrips } from '@/utils/quoteUtils'
import QuoteFormTripDetailNew from '@/components/QuoteFormTripDetailNew.vue'
import { disableRiskTypes } from '@/services/trips'

const customerFirstNameRules = [
  isRequired(true, isNotEmpty, {
    req: 'First Name Required',
    error: 'First Name Required',
  }),
]
const customerLastNameRules = [
  isRequired(true, isNotEmpty, {
    req: 'Last Name Required',
    error: 'Last Name Required',
  }),
]
const customerEmailRules = [
  isRequired(true, validateEmail, {
    req: 'Email Required',
    error: 'Email required and must be valid',
  }),
]
const customerPhoneRules = [
  isRequired(true, isNotEmpty, {
    req: 'Phone Required',
    error: 'Phone required and must be valid',
  }),
]

export default {
  components: {
    TripsFormDetail,
    TripsFormNotes,
    UserSelector,
    Confirm,
    QuoteFormTripDetailNew,
    ImpactedTripSelector,
  },
  directives: { mask },
  beforeRouteLeave(to, from, next) {
    if (to.name && to.name === 'login') {
      next()
    }
    const tripChanged =
      JSON.stringify(this.cachedTrip) !== JSON.stringify(this.trip)
    if (
      !this.isModeView &&
      (tripChanged || this.hasDisabledRiskTypes) &&
      !this.lockSaveButton
    ) {
      this.unsavedChangesLeaveAction = next
      this.handleUnsavedChanges()
    } else {
      next()
    }
  },
  props: {
    mode: { type: String, default: null },
    id: { type: String, default: null },
  },
  metaInfo: {
    title: 'Edit Reservation',
  },
  data() {
    return {
      isNotEmpty,
      trip: {},
      isRequired,
      phoneFormatFilter,
      currencyFilter,
      customerFirstNameRules,
      customerLastNameRules,
      customerEmailRules,
      customerPhoneRules,
      isConverted: false,
      isBASQuote: false,
      showApproveQuote: false,
      dialogType: undefined,
      actionsDialog: false,
      exchangeRate: 1,
      showAlternativeCurrency: false,
      lockSaveButton: false,
      notesDialog: false,
      validationResults: {},
      customerAccount: null,
      customerSearchFields: {
        firstName: {
          column: {
            _t_id: '2610b5d1',
            prop: 'firstName',
            filterType: 'contains',
          },
        },
        lastName: {
          column: {
            _t_id: 'e5b0247a',
            prop: 'lastName',
            filterType: 'contains',
          },
        },
        phone: {
          column: { _t_id: '5db94195', prop: 'phone', filterType: 'contains' },
        },
        email: {
          column: { _t_id: '63f4ed7a', prop: 'email', filterType: 'contains' },
        },
      },
      customer: {
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        email: undefined,
      },
      loggedInUserCompanyName: undefined,
      leadSourceSearchTerm: undefined,
      checkoutPageSearchTerm: undefined,
      validationKey: undefined,
      distanceUpdateKey: undefined,
      dueDateKey: undefined,
      saveQuoteError: undefined,
      saveQuoteErrorMessage: undefined,
      toggleSubTabs: 0,
      customerFilters: filter(),
      quotesFilters: filter(),
      reservationsFilters: filter(),
      pastQuotes: [],
      pastReservations: [],
      isCustomerSelected: false,
      isLeadSourceSelected: false,
      isCheckoutPageSelected: false,
      conflictSidebarIsOpen: false,
      pastShowAll: false,
      showCheckoutPageError: false,
      showLeadSourceError: false,
      suggestedCustomers: [],
      suggestedLeadSources: [],
      suggestedCheckoutPages: [],
      previousTripNotes: '',
      previousTripDescription: '',
      tripTypes: [],
      chargeTypes: [],
      vehicleTypes: [],
      rateTypes: [],
      showPastQuotes: false,
      showMarketRatesDialog: false,
      showMarketsRatesMap: false,
      hasCustomerFieldInputs: false,
      selectedTrip: 'trip-0',
      selectedTripSection: 'details', // or 'payment'
      selectedCustomerTab: 'quotes', // or 'reservations'
      // FIXME: double check and then remove the next line.
      submitStatus: null,
      displaySave: false,
      reservation: {},
      isTripContactSelected: true,
      userData: {},
      cachedTrip: null,
      unsavedChangesWarningDialog: false,
      unsavedChangesLeaveAction: null,
      impactedTripIds: [],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      quoteInProgress: 'quotes/quoteInProgress',
      createNewSelected: 'quotes/getCreateCustomerAccount',
      selectedCustomerAccount: 'quotes/selectedCustomerAccount',
      availableCustomerAccounts: 'quotes/availableCustomerAccounts',
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeView() {
      return this.mode === 'view'
    },
    currentTrip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    sideBarToggled() {
      const state = this.getSideBar()

      if (state) {
        return 'why'
      }

      return 'not'
    },
    selectedTripIndex() {
      const selectedTrip = this.selectedTrip || ''
      return selectedTrip.substring(
        selectedTrip.indexOf('-') + 1,
        selectedTrip.length
      )
    },
    modeTitle() {
      let modeTitleString = 'Add New'
      if (this.isModeView) {
        modeTitleString = 'View'
      }
      if (this.isModeEdit) {
        modeTitleString = 'Edit'
      }
      return modeTitleString
    },
    isRecurringTrip() {
      return this.trip.hasRecurrence || !!this.trip.parentTripId
    },
    disabledRiskTypes() {
      return this.$store.getters['risks/disabledRiskTypes'] || []
    },
    hasDisabledRiskTypes() {
      return this.disabledRiskTypes.length > 0
    },
  },
  async mounted() {
    await this.getCustomerAccounts()
    this.clearDisabledRiskTypes()
    this.loggedInUserCompanyName =
      this.currentUser?.company?.name || 'CharterUP'

    const reservation = await this.getReservationV2({
      reservationId: this.id,
      companyId: this.currentUser.companyId,
    }).then((data) => data.data)
    this.reservation = reservation
    const tripId = reservation?.trip?.tripId || reservation?.tripId

    const quoteTypesData = await this.getQuoteTypes().catch((e) => e)
    const quoteTypes = quoteTypesData.data || {}

    this.paymentTypes = quoteTypes.payments
    this.rateTypes = quoteTypes.rates
    this.vehicleTypes = quoteTypes.vehicleTypes.filter(
      (vehicleType) => vehicleType.key !== 'unassigned'
    )

    this.tripTypes = quoteTypes.trips
    this.chargeTypes = quoteTypes.charges

    const trip = await this.getTripV3(tripId).then((data) => data.data?.trip)

    if (this.isShuttleCreateQuoteEnabled && trip?.tripVehicleGroups?.length) {
      await this.initializeQuoteModule()
      const trips = convertTripDTOsToRouteTrips([trip])
      this.setTripsModule({ trips })
    }
    if (trip.vehicles) {
      trip.requiredVehicles = trip.vehicles
    }
    this.reservation.trip = trip

    this.previousTripNotes = this.trip.notes
    this.previousTripDescription = this.trip.description
    trip.stops.sort((a, b) => (a.orderIndex > b.orderIndex ? 0 : -1))

    trip.stops.forEach((stop) => {
      if (stop.address && stop.address.name) {
        stop.address.addressName = stop.address.name
      }
      if (stop.pickupDatetime) {
        let pickupTime = DateTime.fromISO(stop.pickupDatetime, {
          zone: stop.address.timeZone,
        }).toISOTime()
        const pickupTimeParts = pickupTime.split(':')
        pickupTime = `${pickupTimeParts[0]}:${pickupTimeParts[1]}`
        stop.pickupDate = DateTime.fromISO(stop.pickupDatetime, {
          zone: stop.address.timeZone,
        }).toISODate()
        stop.pickupTime = pickupTime
      }
      if (stop.dropoffDatetime) {
        let dropoffTime = DateTime.fromISO(stop.dropoffDatetime, {
          zone: stop.address.timeZone,
        }).toISOTime()
        const dropoffTimeParts = dropoffTime.split(':')
        dropoffTime = `${dropoffTimeParts[0]}:${dropoffTimeParts[1]}`
        stop.dropoffDate = DateTime.fromISO(stop.dropoffDatetime, {
          zone: stop.address.timeZone,
        }).toISODate()
        stop.dropoffTime = dropoffTime
      }
    })

    if (!trip.tripContact) {
      this.userData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }
    } else {
      this.userData = trip.tripContact
    }

    this.trip = trip
    setTimeout(() => {
      this.cachedTrip = deepClone(trip)
    }, 2500)

    EventBus.$on('refresh-detail', async () => {
      const reservation = await this.getReservationV2({
        reservationId: this.id,
        companyId: this.currentUser.companyId,
      }).then((data) => data.data)
      this.setReservationData(reservation)
    })
  },
  beforeDestroy() {
    EventBus.$off('refresh-detail')
  },
  methods: {
    ...mapGetters({
      getSideBar: 'dashboard/getSideBar',
    }),
    ...mapActions({
      getTripV3: 'trips/getTripV3',
      getRecurringTripCountFromDate: 'trips/getRecurringTripCountFromDate',
      getReservationV2: 'reservations/reservationByIdv2',
      saveQuote: 'quotes/saveQuote',
      getQuoteTypes: 'types/getQuoteTypes',
      getCustomerAccounts: 'quotes/getCustomerAccounts',
      showAlert: 'app/showAlert',
      initializeQuoteModule: 'quoteForm/initialize',
      setTripsModule: 'quoteForm/setTrips',
      clearDisabledRiskTypes: 'risks/clearDisabledRiskTypes',
    }),
    setReservationData(reservationData) {
      this.reservation = Object.assign({}, this.reservation, reservationData)
    },
    backToDetail() {
      this.$router.push({ name: 'reservation-detail', params: { id: this.id } })
    },
    handleUnsavedChanges() {
      this.unsavedChangesWarningDialog = true
    },
    confirmLeave() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction()
    },
    confirmStay() {
      this.unsavedChangesWarningDialog = false
      this.unsavedChangesLeaveAction(false)
    },
    async validateTripItineraryConflicts() {
      if (this.isShuttleCreateQuoteEnabled && this.currentTrip) {
        const tripToValidate = buildValidateTripPayload(
          deepClone(this.currentTrip)
        )
        tripToValidate.hardConflictOverride = true
        const validationResponse = await validateTrip(tripToValidate)
        const hasConflicts = validationResponse?.data?.hasConflicts
        if (hasConflicts) {
          this.openConflictSidebarValidation()
        } else {
          this.saveRouteTrip(deepClone(this.currentTrip))
        }
      } else {
        const tripToValidate = buildValidateTripPayload(this.trip)
        tripToValidate.hardConflictOverride = true
        const validationResponse = await validateTrip(tripToValidate)
        const hasConflicts = validationResponse?.data?.hasConflicts

        if (hasConflicts) {
          this.openConflictSidebarValidation()
        } else {
          this.saveQuoteHandler()
        }
      }
    },
    async openConflictSidebarValidation() {
      this.conflictSidebarIsOpen = true
      const action = 'app/openSidebarDialog'
      const component = () =>
        import('../components/TripItineraryConflictSidebar.vue')
      this.$store.dispatch(action, {
        data: {
          title: 'Driver/Vehicle Conflict',
          cancelSubmitDueToConflict: this.cancelSubmitDueToConflict,
        },
        component,
      })
    },
    cancelSubmitDueToConflict() {
      this.conflictSidebarIsOpen = false
      this.$store.dispatch('app/closeDialog')
    },
    saveRouteTrip(trip) {
      setTimeout(async () => {
        const hasFailures = Object.keys(this.validationResults).some((key) => {
          return op(this.validationResults, `${key}/hasFailures`)
        })
        if (!hasFailures) {
          this.lockSaveButton = true
          trip.startDate = trip.stops?.[0]?.pickupDatetime || null
          trip.tripContactId = this.userData.customerId
          trip.impactedTripIds = this.impactedTripIds
          try {
            await this.$store.dispatch('trips/modifyTripByStops', {
              tripId: trip.tripId,
              hardConflictOverride: true,
              payload: trip,
            })

            await this.$router.push({
              name: 'reservation-detail',
              params: {
                id: this.id,
                ignoreCache: true,
              },
            })

            this.showAlert({
              message: 'Changes Saved',
            })

            if (this.reservation.referralStatus != 'not_offered') {
              await this.$store.dispatch('app/openSidebarDialog', {
                reservationId: this.parentReservationId || this.reservationId,
                data: {
                  tripId: this.reservation.trip.tripId,
                  referredTo: this.reservation.referredTo,
                  title: 'Send Itinerary Update',
                },
                component: () =>
                  import('../components/ReservationSendItineraryUpdate.vue'),
              })
            }
          } catch (error) {
            this.lockSaveButton = false
            this.showAlert({
              type: 'error',
              message: 'There was an error saving the trip.',
            })
          }
        }
      }, 1000)
    },
    async saveQuoteHandler(saveForLater = false) {
      this.validationKey = v4()
      setTimeout(async () => {
        for (const vehicle of this.trip.vehicles) {
          vehicle.vehicleTypeId = vehicle.vehicleType.id
        }

        this.trip.stops.forEach((stop, stopIndex) => {
          stop.active = true
          stop.orderIndex = stopIndex
          const timeZone = stop?.address?.timeZone

          if (stop.address) {
            stop.address.name = stop.address.addressName
          }

          if (stop.pickupDate) {
            let pickupDateTime
            if (stop.pickupTime) {
              pickupDateTime = DateTime.fromJSDate(
                new Date(`${stop.pickupDate}T${stop.pickupTime}:00`)
              ).setZone(timeZone, { keepLocalTime: true })
            } else {
              pickupDateTime = DateTime.fromJSDate(
                new Date(`${stop.pickupDate}T12:00:00`)
              ).setZone(timeZone, {
                keepLocalTime: true,
              })
            }
            stop.pickupDatetime = pickupDateTime.toISO()
          }
          if (stop.dropoffDate) {
            let dropoffDateTime
            if (stop.dropoffTime) {
              dropoffDateTime = DateTime.fromJSDate(
                new Date(`${stop.dropoffDate}T${stop.dropoffTime}:00`)
              ).setZone(timeZone, { keepLocalTime: true })
            } else {
              dropoffDateTime = DateTime.fromJSDate(
                new Date(`${stop.dropoffDate}T12:00:00`)
              ).setZone(timeZone, {
                keepLocalTime: true,
              })
            }

            stop.dropoffDatetime = dropoffDateTime.toISO()
          }
        })
        const hasFailures = Object.keys(this.validationResults).some((key) => {
          return op(this.validationResults, `${key}/hasFailures`)
        })

        if (!hasFailures) {
          this.lockSaveButton = true
          this.trip.startDate = this.trip.stops?.[0]?.pickupDatetime || null
          this.trip.tripContactId = this.userData.customerId
          this.trip.impactedTripIds = this.impactedTripIds
          try {
            await this.$store.dispatch('trips/modifyTripByStops', {
              tripId: this.trip.tripId,
              hardConflictOverride: true,
              payload: this.trip,
            })

            if (this.hasDisabledRiskTypes) {
              const payload = { riskTypeIds: this.disabledRiskTypes }
              await disableRiskTypes(this.trip.tripId, payload)
              this.clearDisabledRiskTypes()
            }

            await this.$router.push({
              name: 'reservation-detail',
              params: {
                id: this.id,
                ignoreCache: true,
              },
            })

            this.showAlert({
              message: 'Changes Saved',
            })

            if (this.reservation.referralStatus != 'not_offered') {
              await this.$store.dispatch('app/openSidebarDialog', {
                reservationId: this.parentReservationId || this.reservationId,
                data: {
                  tripId: this.reservation.trip.tripId,
                  referredTo: this.reservation.referredTo,
                  title: 'Send Itinerary Update',
                },
                component: () =>
                  import('../components/ReservationSendItineraryUpdate.vue'),
              })
            }
          } catch (error) {
            this.lockSaveButton = false
            this.showAlert({
              type: 'error',
              message: 'There was an error saving the trip.',
            })
          }
        }
      }, 1000)
    },
    editCustomerSelection(id) {
      const path = { name: 'customers.details', params: { id } }
      const routeData = this.$router.resolve(path)
      window.open(routeData.href, '_blank')
    },
    captureValidationResults(validationResults) {
      const { tripIndex, tripModule, hasFailures = false } = validationResults
      this.validationResults[tripIndex] = { hasFailures }
      this.validationResults[`${tripIndex}-${tripModule}`] = { hasFailures }
      this.$forceUpdate()
    },
    cancelNoteDialog() {
      this.trip.description = this.previousTripDescription
      this.trip.notes = this.previousTripNotes
      this.notesDialog = false
    },
    saveNoteDialog() {
      this.previousTripDescription = this.trip.description
      this.previousTripNotes = this.trip.notes
      this.notesDialog = false
    },
    setDueDateKey() {
      this.$nextTick(() => {
        this.dueDateKey = v4()
      })
    },
    async changeCurrencyType(currencyType) {
      if (currencyType !== 'USD') {
        this.showAlternativeCurrency = true
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate =
          exchangeRateData?.data?.rates?.[currencyType] || null
      }
    },
    showDialog(type) {
      if (type === 'approveQuote' && this.isBASQuote) {
        type = 'approveBASQuote'
      }
      this.actionsDialog = true
      this.dialogType = type
    },
    onCloseDialog() {
      this.actionsDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.why {
  margin-top: -45px;
}

.not {
  // margin-top: -25px;

  h1 {
    margin-left: 33px;
  }
  // max-width: 870px;
}

@media only screen and (max-width: 1140px) {
  .why {
    margin-top: -25px;

    h1 {
      margin-left: 33px;
    }
  }
}

.market-rates-button {
  position: fixed;
  top: 90px;
  right: 56px;
  z-index: 5;
  width: 52px;
  height: 52px;
  background-color: $white;
  border: 1px solid $blue;
}

.customer-suggestions {
  margin-top: 5px;
}

.customer-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .customer-header-actions {
    flex-grow: 0.1;
    justify-content: flex-end;
  }

  .customer-name {
    flex-grow: 0.1;
    margin-bottom: 20px;
  }

  .customer-phone {
    flex-grow: 0.1;
  }

  .customer-account {
    flex-grow: 0.3;
  }

  .customer-email {
    flex-grow: 0.1;
  }

  .customer-quotes {
    flex-grow: 0.4;
    margin-top: 10px;
  }

  .icon-padded {
    padding: 10px;
  }
}

.customer-suggestion {
  padding: 16px 0 16px 0;

  &:not(:last-child):not(:only-child) {
    border-bottom: 1px solid $gray-light;
  }
}

.quote-trip-tab {
  position: relative;

  &::v-deep .v-tabs__item {
    padding-right: 10px;
    padding-left: 10px;
  }

  &::v-deep.failed-validation {
    .v-tabs__item {
      font-weight: bold;
      color: $error;
    }
  }

  .icon-button-close {
    position: relative;
    right: 0;
    width: 5px;
    height: 5px;
    visibility: hidden;

    &::v-deep .v-icon {
      font-size: 12px;
    }
  }

  &:hover .icon-button-close {
    visibility: visible;
  }

  .active-error {
    color: $error;
  }
}

.new-customer-container {
  align-content: center;
  justify-content: center;
}

.quote-totals {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba($black-base, 0.2),
    0 3px 10px 0 rgba($black-base, 0.19);
}

.gray-bar {
  width: 100%;
  height: 10px;
  background-color: $gray-light;
}

.dark-gray-bar {
  width: 100%;
  height: 10px;
  background-color: gray;
}

.green-bar {
  width: 100%;
  height: 10px;
  background-color: rgba(0, 128, 0, 0.5);
}

.info-padded {
  padding-left: 12px;
}

.error-message {
  margin: 10px;
  font-size: 12px;
  color: $error !important;
}

.button-group {
  margin-top: 20px;

  :first-child {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :last-child {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error-non-selected {
  color: $error;
}

.tab-icon-close {
  margin-top: -2px;
}

.quote-totals-save-actions {
  margin-top: 8px;
  margin-right: 8px;
}

::v-deep.v-card__title {
  color: $white;
  background-color: $blue !important;
}

.past-quotes-label {
  font-size: 23px;
}

.free-quote-warning {
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 10px;
  background-color: $gray-light;
  border-radius: 6px;
}

.action-button {
  padding: 5px 15px;
}

.notes-container {
  display: flex;
  justify-content: flex-end;
}

.actual-footer {
  margin-top: 3%;
}
</style>
