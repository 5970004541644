<template>
  <div>
    <v-layout row>
      <p>
        <a
          v-if="!isModeView && !tripNotesExist"
          @click="editTripNotes(tripData)"
        >
          Add Trip Notes, Office Notes, and Driver Notes
        </a>
      </p>
      <v-flex v-if="tripNotesExist" xs12>
        <v-layout row>
          <v-flex>
            <p>
              Trip Notes
              <span class="edit-button" @click="editTripNotes(tripData)">
                Edit
              </span>
            </p>
            <p>
              <i
                v-html="previousTripNotes.customerNotes || tripData.description"
              />
            </p>
          </v-flex>
          <v-flex>
            <p>
              Office Notes
              <span class="edit-button" @click="editTripNotes(tripData)">
                Edit
              </span>
            </p>
            <div>
              <p v-html="previousTripNotes.officeNotes || tripData.notes" />
            </div>
          </v-flex>
          <v-flex v-if="isSuperAdmin">
            <p>
              Driver Notes
              <span class="edit-button" @click="editTripNotes(tripData)">
                Edit
              </span>
            </p>
            <div>
              <p v-html="previousTripNotes.driverNotes" />
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
const INTERNAL_NOTE_TYPE = 1
const CUSTOMER_NOTE_TYPE = 2
const DRIVER_NOTE_TYPE = 3

import { authComputed } from '@/state/helpers'
export default {
  props: {
    reservationId: { type: Number, default: undefined },
    tripData: {
      type: Object,
      default: () => ({}),
    },
    mode: { type: String, default: undefined },
  },
  data() {
    return {
      activeTrip: undefined,
    }
  },
  computed: {
    ...authComputed,
    isModeView() {
      return this.mode === 'view'
    },
    tripNotesExist() {
      return (
        this.tripData?.description?.length ||
        this.tripData?.notes?.length ||
        this.previousTripNotes.customerNotes?.length ||
        this.previousTripNotes.officeNotes?.length ||
        this.previousTripNotes.driverNotes?.length
      )
    },
    previousTripNotes() {
      let notes = this.tripData?.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })
        let officeNotes = notes.find((note) => {
          return note.noteType === INTERNAL_NOTE_TYPE
        })
        let driverNotes = notes.find((note) => {
          return note.noteType === DRIVER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html ||
          customerNotes?.note ||
          this.tripData?.description
        previousNotes.officeNotes =
          officeNotes?.html || officeNotes?.note || this.tripData?.notes
        previousNotes.driverNotes = driverNotes?.html || driverNotes?.note

        return previousNotes
      }

      return {}
    },
  },
  methods: {
    editTripNotes(trip) {
      const component = () => import('./ReservationAddTripNotes.vue')
      this.activeTrip = trip
      this.$store.dispatch('app/openSidebarDialog', {
        reservationId: this.reservationId,
        data: {
          reservationId: this.reservationId,
          trip: this.activeTrip,
          reservationType: 0,
          title: 'Trip Notes',
        },
        component,
      })
    },
  },
}
</script>

<style lang="scss">
.edit-button {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-size: 13px;
  padding: 7px;
}
</style>
