import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const getDistanceAndDurationBetweenLocations = async function (
  locationsArray
) {
  let matrix
  try {
    const apiData = await axios.post(`https://${baseUrl()}/distancematrix`, {
      origins: locationsArray.filter((value) => !!value),
      destinations: locationsArray.filter((value) => !!value),
    })
    matrix = apiData?.data?.json?.rows
  } catch (error) {
    matrix = []
  }

  let distance = 0.0
  let duration = 0.0
  for (let n = 1; n < matrix.length; n++) {
    distance += matrix[n - 1].elements[n].distance.value
    duration += matrix[n - 1].elements[n].duration.value
  }
  return { distance, duration }
}
